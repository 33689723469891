
 .container-onboarding{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;

 }

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 10%;
  position: relative; 
  height: fit-content;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 50%; 
  transform: translateX(-50%);
  width: 2px;
  background-color: #C4C4C4;
  z-index: 0;
  height: 310px; 
}

.timelineItemActive, .timelineItem {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-bottom: 126px;
  z-index: 1;
  position: relative;
  border: 1px solid #fff;
}

.timelineItemActive {
  background-color: #007BFF;
  border: 2px solid #007BFF;
}

.timelineItem {
  background-color: #fff;
  border: 2px solid #C4C4C4;
}

.content-onboard {
  flex-basis: 50%;
  max-width: 600px;
  text-align: left;
}

.heading {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  color: #6C757D;
  line-height: 1.5;
  margin-bottom: 40px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}

.previousButton, .nextButton {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.previousButton {
  background-color: #6C757D;
  color: #fff;
}

.nextButton {
  background-color: #6C757D;
  color: #fff;
}

.imageContainer {
  flex-basis: 30%;
  max-width: 400px;
  height: auto;
  object-fit: fill;
}



@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 0px 20px;
  }

  .timeline {
    flex-direction: row;
    justify-content: center;
    flex-basis: auto;
    margin-bottom: 20px;
    height: auto;
  }

  .timeline::before {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 2px;
    width: 100%;
  }

  .timelineItemActive, .timelineItem {
    margin-bottom: 0;
    margin-right: 50px;
  }

  .content {
    flex-basis: auto;
    max-width: 100%;
    text-align: center;
  }

  .buttonContainer {
    flex-direction: column;
    align-items: center;
  }

  .previousButton, .nextButton {
    width: 100%;
    margin-bottom: 10px;
    background-color:#6C757D;
  }

  .imageContainer {
    
    margin-top: 20px;
    object-fit: cover;
  }
}
.checkbox-form {
  display: flex;
  flex-direction: column; 
}

.checkboxLabel {
  display: flex; 
  font-size:20.21px;
  font-weight: 500;
  color: #616161;
  margin-bottom: 10px; 
}

.checkbox {
  margin-right: 10px; 
}
.timelineItemActive::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 50%;
  left: -4px;
  top: -4px;
}  
.timelineItemActive{
  border: 4px solid #fff;

}
.nextButton{
  margin-top: 60px;
  width: 150px;
}
.previousButton{
  margin-top: 60px;

}
.step2-legit-img-container{
  height: auto;
  width: 156px;
  padding-top: 8px;
  margin-right: 16px;
  margin-bottom: 18px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  
  
}
.step2-legit-img {
  width: 100%;
  height: 70px;
  border-radius: 12px;
  padding: 4px;
  
  object-fit: fill;
}
.step2-legit-img-container p{
  font-size: 12px;
  font-weight: 700;
}
.img-banner{
  width: 100%;
}
.input-checkbox-heading{
  font-size: 12.80px;
  font-weight: 700;
  color: #616161;
  font-family: Zen Kaku Gothic Antique;
  word-wrap: break-word;
  margin-left: 5px;
}
.step2-heading-intrest{
  font-size: 32;
  font-weight: 600;
  font-family: Montserrat;
  color: #616161
  ;


}
.step2-topic-intrest{
  font-size: 32px;
  font-family: Montserrat;
  font-weight: 600;
  color: #616161
  ;


}
.step2-pick{
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 500;
  color: #616161

}
.suggestions-list {
  border: 1px solid #ccc;
  border-top: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}
.step2-checkbox-label{
  margin-left: 10px;
}
.step-2-input-checkbox{
  margin-left:10px;
  border-radius: 50%;
}
.input-checkbox#SelectAll{
  border-radius: 50%;

}





