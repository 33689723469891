.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.loader-text {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #2d3648; 
}
