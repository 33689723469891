.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
}

.error-content {
  max-width: 600px;
  margin: 0 auto;
}

.error-image img {
  width: 100%;
  max-width: 400px;
  height: auto;
}



.error-buttons {
  margin-top: 20px;
}

.error-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
}

.error-buttons button:hover {
  background-color: #0056b3;
}
