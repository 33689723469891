@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400..800&display=swap');



body {
  overflow-x: hidden;
}
.container {
  overflow-x: unset;
}
.fade.modal-backdrop.show {
  opacity: 0.3;
}

.icon-chat {
  position: fixed;
  bottom: 100px;
  height: 50px;
  width: 50px;
  background-color: #00004a;
  object-fit: contain;
  border-radius: 50%;
  right: 30px !important;
  z-index: 1000; /* Ensure it stays on top of other elements */
  cursor: pointer; /* Make it clear that this is clickable */
  height: 50px;

  animation: pulse 2s infinite; /* Apply pulse animation */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for hover effects */
}
@media (max-width: 786px) and (min-width: 320px) {
  .icon-chat {
    position: fixed;
    bottom: 100px !important;
    height: 50px;
    width: 50px;
    background-color: #00004a;
    object-fit: contain;
    border-radius: 50%;
    right: 30px !important;
    z-index: 1000; /* Ensure it stays on top of other elements */
    cursor: pointer; /* Make it clear that this is clickable */
    height: 50px;
  
    animation: pulse 2s infinite; /* Apply pulse animation */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for hover effects */
  }
}
.icon-chat img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

}

/* Keyframes for the pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Optional hover effect for added interactivity */
.icon-chat:hover {
  transform: scale(1.15); /* Enlarge icon a bit more on hover */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0); /* Add shadow for emphasis */
}
hr{
  height: 1px !important;
  border-radius: 60px;
  background-color: #D9D9D9;


}
.horizontal-line{
  height: 1px !important;
  border-radius: 60px;
  background-color: #D9D9D9;

}