.notification-panel {
  position: absolute;
  top: 50px;
  right: 20px;
  width: 407px;
  height: 289px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.notification-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.clear-all{
  font-weight: 600;
  font-family: Open Sans;
  font-size: 9px;
  text-align: right;

}

.clear-all:hover {
  text-decoration: underline;
}

.notification-list {
  max-height: 400px;
  overflow-y: auto;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  border: 1px solid #eee;
  border-radius: 10px;
}

.notification-item:hover {
  background-color: #f5f5f5;
}

.notification-details {
  display: flex;
  flex-direction: column;
}

.notification-title {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.notification-message {
  color: #555;
  font-size: 14px;
  margin-bottom: 5px;
}

.notification-timestamp {
  font-size: 12px;
  color: #888;
}

.notification-dismiss {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.notification-dismiss:hover {
  text-decoration: underline;
}
.notification-timestamp{
  margin-left: 100px;
}

