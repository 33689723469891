.user-profile a {
  font-size: 25px;
  text-decoration: none;
}
.logo-btn-cls{
  background: #00004a;
}
.notification-icon {
  margin-right: 18px;
}
.user-icon {
  margin-right: 12px;
}
.circular--portrait {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
}
.circular--portrait img {
  width: 100%;
  height: auto;
}

.user-profile {
  position: relative;
  display: flex;
}

.dropdown-menu {
  min-width: auto;
  right: 0;
  left: auto;
}

.user-icon {
  cursor: pointer;
}

.notification-icon {
  margin-right: 15px;
  cursor: pointer;
}
.btn-logo{
  background-color:#00004A;
  color: #fff;


}
@media (max-width:375px) and (max-width:425px) and (max-width:768px) {
  .header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navbar-header-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}