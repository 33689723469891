section.footer-section {
    background-color: #00004A;
    position: fixed;
    bottom: 0;
    width: 100%;
}
.footer-content h3{
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;


}
@media (max-width: 786px) and (min-width: 320px) {
    .footer-content {
        height: 5vh;
    }
}
