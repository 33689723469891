@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.login-container {
  border: 1px solid gray;
}

.login-section {
  padding-top: 80px;
}

.form-heading {
  margin-top: 30px;
}

.login-submit-btn {
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  background-color: #00004A;
  border: 1px solid #00004A;
  align-self: stretch;
  border-radius: 50px;
  width: 100%;
}

.keep-me-checkbox {
  font-size: 14px;
}

#formBasicEmail {
  border-top-width: 0px;
  border-right-width: 0px;
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 14px;
  border-left-width: 0px;
  border-top-color: rgb(201, 216, 236);
  border-right-color: rgb(201, 216, 236);
  border-bottom-color: rgb(201, 216, 236);
  border-left-color: rgb(201, 216, 236);
  border-bottom-width: 1px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

#formBasicEmail {
  border-top-width: 0px;
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 14px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-color: rgb(201, 216, 236);
  border-bottom-width: 1px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

#formBasicPassword {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 14px;
  border-bottom-left-radius: 0px;
  border-bottom-color: rgb(201, 216, 236);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.styled-heading {
  margin-top: 22px;
  margin-bottom: 22px;
  display: flex;
}

.styled-heading:before,
.styled-heading:after {
  color: white;
  content: "";
  flex: 1;
  border-bottom: groove 2px;
  margin: auto 0.25em;
  box-shadow: 0 -1px;
  /* or 0 1px if border-style:ridge */
}

.login-with-google {
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  background-color: #00004A;
  border: 1px solid #00004A;
  color: #fff;
  gap: 10px;
  align-self: stretch;
  border-radius: 50px;
  width: 100%;
}

.form-col {
  padding-left: 50px;
  padding-right: 50px;
}

.signup-link {
  color: #757575;
  font-family: "roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 42px;
}

.forget-pass-link {
  color: #616161;
  font-family: "roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.53px;
  /* 204.818% */
  text-decoration-line: underline;
  margin-left: 100px;
}

.copyright-text {
  color: #616161;
  font-size: 14x;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

.form-heading {
  color: #242424;
  text-align: center;
  font-size: 31.25px;
  font-style: normal;
  font-weight: 700;
  line-height: 37.5px;
  /* 120% */
  letter-spacing: -0.5px;
}

#root>div:nth-child(2)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>form:nth-child(2)>div:nth-child(1)>label:nth-child(1) {
  font-size: 14px;
  color: rgb(117, 117, 117);
}

#root>div:nth-child(2)>div:nth-child(1)>div:nth-child(1)>div:nth-child(1)>div:nth-child(2)>form:nth-child(2)>div:nth-child(2)>label:nth-child(1) {
  font-size: 14px;
  color: rgb(117, 117, 117);
}

.login-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .image-col {
    display: none;
  }

  .form-col {
    padding-left: 25px;
    padding-right: 25px;
  }

  .login-section {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .image-col {
    display: none;
  }
}

#formBasicName {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 0;
  border-bottom-color: rgb(201, 216, 236);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  font-family: Nunito Sans;
  font-weight: 400;
  font-size: 14px;
}

input#email {
  border-bottom: 1px solid #e7e7e7;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding: 0;
  border-radius: 0px;
  font-family: Nunito Sans;
  font-weight: 400;
  font-size: 14px;
}

input#password {
  border-bottom: 1px solid #e7e7e7;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding: 0;
  border-radius: 0px;
  font-family: Nunito Sans;
  font-weight: 400;
  font-size: 14px;
}

#formBasicConfirmPassword {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-color: rgb(201, 216, 236);
  padding: 0;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  font-family: Nunito Sans;
}

.password-visibility-btn {
  cursor: pointer;
}
input#formBasicEmail {
  padding: 0 !important;
}
input#formBasicPassword {
  padding: 0;
}